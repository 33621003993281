import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';

import {Button, Col, Row, Space} from 'antd';
import {Typography} from 'antd';
import {Tooltip} from 'antd';

import {InfoCircleOutlined} from '@ant-design/icons';

const {Title, Paragraph} = Typography;

interface IBannerProps {
  items: {key: number; displayTitle: string; subtitle: string; tooltip: string; image: string; link: string}[];
}

interface IStyledProps {
  image: string;
}

const Banner: React.FC<IBannerProps> = ({items}) => {
  return (
    <Row gutter={[20, 20]} justify="space-between">
      {items.map(item => (
        <BannerContainerInner xs={24} sm={12} md={12} key={item.key}>
          <BannerTextStyled {...item}>
            <BannerTextInnerStyled>
              <ContentRowStyled xs={24} sm={24}>
                <TitleStyled level={4}>{item.displayTitle}</TitleStyled>
                <ColStyled sm={19}>
                  <ParagraphStyled>{item.subtitle}</ParagraphStyled>
                </ColStyled>
              </ContentRowStyled>
            </BannerTextInnerStyled>
            <TooltipRowStyled>
              <Space direction={'vertical'} size={'large'}>
                <Link to={item.link}>
                  <Button type={'primary'} size={'large'}>
                    Рассчитать
                  </Button>
                </Link>
                <Tooltip title={item.tooltip} placement="bottomLeft" overlayStyle={{maxWidth: 500, width: '95%'}}>
                  <InfoCircleOutlined />
                  <span> Информация</span>
                </Tooltip>
              </Space>
            </TooltipRowStyled>
          </BannerTextStyled>
        </BannerContainerInner>
      ))}
    </Row>
  );
};

const BannerContainerInner = styled(Col)`
  position: relative;
  height: 300px;
  border-radius: 10px;
  @media screen and (max-width: 640px) {
    height: 320px;
  }
`;

const TitleStyled = styled(Title)`
  width: 100%;
`;

const ParagraphStyled = styled(Paragraph)`
  max-width: 368px;
`;

const BannerTextStyled = styled.div<IStyledProps>`
  display: flex;
  flex-direction: column;
  padding: 24px;
  height: 100%;
  justify-content: space-between;
  background: url(${props => props.image}) no-repeat;
  background-color: #f5f5f5;
  background-position-x: 90%;
  background-position-y: 85%;
  background-size: auto 150px;

  @media screen and (max-width: 640px) {
    padding: 16px;
    background-size: auto 110px;
    background-position-x: 95%;
    background-position-y: 85%;
  }

  @media screen and (max-width: 320px) {
    padding: 16px;
    background-size: auto 120px;
    background-position-x: 90%;
    background-position-y: 85%;
  }
`;

const TooltipRowStyled = styled(Row)`
  position: absolute;
  bottom: 32px;
`;

const BannerTextInnerStyled = styled.div``;

const ContentRowStyled = styled(Col)`
  padding: 0 !important;
`;

const ColStyled = styled(Col)`
  padding: 0 !important;
`;
export default Banner;
