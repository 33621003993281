import React from 'react';
import styled from 'styled-components';

import {Card as AntdCard, Row} from 'antd';

import {Col} from 'antd';
import {Typography} from 'antd';

const {Paragraph} = Typography;

const CardBodyStyled = {
  padding: '0',
};

interface ICardProps {
  items: {key: number; caption: string; image: string}[];
}

const Card: React.FC<ICardProps> = ({items}) => {
  return (
    <Row gutter={[10, 10]} justify={'center'}>
      {items.map(item => (
        <Col xs={12} sm={12} md={6} lg={6} key={item.key}>
          <AntdCardStyled bodyStyle={CardBodyStyled} bordered={false}>
            <ImageStyled alt="example" src={item.image} />
            <CardTextStyled strong>{item.caption}</CardTextStyled>
          </AntdCardStyled>
        </Col>
      ))}
    </Row>
  );
};

const CardTextStyled = styled(Paragraph)`
  padding: 15px;
  width: 100%;
`;

const AntdCardStyled = styled(AntdCard)`
  text-align: center;
`;

const ImageStyled = styled.img`
  max-width: 260px;
  max-height: 260px;
  height: auto;
  width: 100%;
`;

export default Card;
