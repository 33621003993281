import React, {useContext} from 'react';
import styled from 'styled-components';
import {Row, Col, Space, Typography} from 'antd';
// import {Button, Carousel} from 'antd';
import {LocaleContext} from '~src/context';
// import {$phone, $tablet} from '~src/theme/media';

// import CommentsSection from '~src/components/organisms/CommentsSection';
import Card from './Card';
import Banner from './Banner';

import MainBannerJpg from '~src/assets/images/main.webp';
// import offerBanner from '~src/assets/images/offer.png';

const {Title, Paragraph} = Typography;

interface IIndexPageProps {}

// const BannerLazy = React.lazy(() => import('./Banner'));
// const CardLazy = React.lazy(() => import('./Card'));
const CommentsSectionLazy = React.lazy(() => import('~src/components/organisms/CommentsSection'));

const IndexPage: React.FC<IIndexPageProps> = () => {
  const isSSR = typeof window === 'undefined';
  // eslint-disable-next-line no-console
  console.log('isSSR = ', isSSR);

  const {
    components: {card, banner},
  } = useContext(LocaleContext);
  // const {
  //   pages: {
  //     index: {mainBanner, advantages, offerBanner},
  //   },
  // } = useContext(LocaleContext);
  const {
    pages: {
      index: {mainBanner, advantages},
    },
  } = useContext(LocaleContext);
  return (
    <React.Fragment>
      <SpaceStyled size={50} direction="vertical">
        <Row align="middle" justify="center">
          <Col span={24}>
            <MainBannerContainerStyled align="top" justify="center">
              <Col xs={22}>
                <ImageTextStyled>
                  <TitleStyled>{mainBanner.title}</TitleStyled>
                  <ParagraphStyled>{mainBanner.subtitle}</ParagraphStyled>
                </ImageTextStyled>
              </Col>
            </MainBannerContainerStyled>
          </Col>
        </Row>
        <Row align="middle" justify="center">
          <Col span={24}>
            <Banner items={banner.app} />
            {/*{!isSSR && (*/}
            {/*  <React.Suspense fallback={<div />}>*/}
            {/*    <BannerLazy items={banner.app} />*/}
            {/*  </React.Suspense>*/}
            {/*)}*/}
          </Col>
        </Row>
        <Row gutter={[0, 25]} align="middle" justify="center">
          <Col span={24}>
            <Title level={2}>{advantages.title}</Title>
            <Paragraph>{advantages.subtitle}</Paragraph>
          </Col>
          <Col span={24}>
            <Card items={card.app} />
            {/*{!isSSR && (*/}
            {/*  <React.Suspense fallback={<div />}>*/}
            {/*    <CardLazy items={card.app} />*/}
            {/*  </React.Suspense>*/}
            {/*)}*/}
          </Col>
        </Row>
      </SpaceStyled>
      {/*<CarouselRowStyled align="middle" justify="center">*/}
      {/*  <Col span={24}>*/}
      {/*    <Carousel autoplay>*/}
      {/*      <OfferBannerContainerStyled align="top" justify="start">*/}
      {/*        <Col span={24}>*/}
      {/*          <OfferBannerTextStyled>*/}
      {/*            <BannerTitleStyled level={2}>{offerBanner.title}</BannerTitleStyled>*/}
      {/*            <BannerSubtitleStyled level={2}>{offerBanner.subtitle}</BannerSubtitleStyled>*/}
      {/*          </OfferBannerTextStyled>*/}
      {/*        </Col>*/}
      {/*        <Button type="primary">Подробнее</Button>*/}
      {/*      </OfferBannerContainerStyled>*/}
      {/*    </Carousel>*/}
      {/*  </Col>*/}
      {/*</CarouselRowStyled>*/}
      <Space size={50} direction="vertical">
        <Row align="middle" justify="center">
          <Col span={24}>
            {/*<CommentsSection mode={'homepage'} />*/}
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <CommentsSectionLazy mode={'homepage'} />
              </React.Suspense>
            )}
          </Col>
        </Row>
      </Space>
    </React.Fragment>
  );
};

const MainBannerContainerStyled = styled(Row)`
  width: 100%;
  height: 420px;
  background-image: url(${MainBannerJpg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 90px;
`;

// const OfferBannerContainerStyled = styled(Row)`
//   width: 100%;
//   height: 420px;
//   background-image: url(${offerBanner});
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
//   padding: 60px;
//   padding-left: 100px;
//   padding-bottom: 80px;
//   display: flex !important;
//   border-radius: 10px;
//
//   @media ${$tablet} {
//     height: 340px;
//     padding: 40px;
//   }
//   @media ${$phone} {
//     height: 250px;
//     padding: 20px;
//   }
// `;

const ImageTextStyled = styled.div`
  color: #ffffff !important;
`;

// const OfferBannerTextStyled = styled.div`
//   max-width: 591px;
//   white-space: pre-line;
// `;

const TitleStyled = styled(Title)`
  &.ant-typography {
    color: #ffffff;
  }
`;

const ParagraphStyled = styled(Paragraph)`
  &.ant-typography {
    color: #ffffff;
  }
  max-width: 560px;
`;

// const CarouselRowStyled = styled(Row)`
//   display: none; // hidden banner
//   margin-bottom: 50px;
// `;

const SpaceStyled = styled(props => <Space {...props} />)`
  margin-bottom: 50px;
  width: 100%;
`;

// const BannerTitleStyled = styled(Title)`
//   font-size: 40px !important;
//   margin-bottom: 10px !important;
//   line-height: 1.2 !important;
//
//   @media ${$tablet} {
//     font-size: 30px !important;
//   }
//   @media ${$phone} {
//     font-size: 24px !important;
//   }
// `;

// const BannerSubtitleStyled = styled(Title)`
//   margin-top: 0 !important;
// `;

export default IndexPage;
