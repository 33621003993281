import React, {useContext} from 'react';
import {IPage} from '~src/types';

import {Modals, PageTemplate} from '~src/components';
import Providers from '~src/components/Providers';
import IndexPage from '~src/components/organisms/IndexPage';
import Seo from '~src/components/Seo';
import {LocaleContext} from '~src/context';

interface IIndexPage extends IPage {}

const IndexPageComponent: React.FC<IIndexPage> = ({location, navigate, path}) => {
  const {
    pages: {
      index: {meta},
    },
  } = useContext(LocaleContext);
  return (
    <Providers>
      <Seo title={meta.title} description={meta.description} keywords={meta.keywords} path={path} />
      <PageTemplate>
        <IndexPage />
      </PageTemplate>
      <Modals navigate={navigate} path={path} location={location} />
    </Providers>
  );
};

IndexPageComponent.displayName = 'IndexPage';
export default IndexPageComponent;
